.status-container {
  display: flex;
  width: 116.72px;
}

.status-item-container {
  display: flex;
  align-items: center;
}

.status-signal {
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 9999px;
  margin-right: 1rem;
}

.status-loading {
  background: #cccccc;
}

.status-pending {
  background: #ffe711;
}

.status-accepted {
  background: #32cc32;
}

.status-rejected,
.status-error {
  background: #c4183c;
}

.registration-header {
  display: flex;
  justify-content: space-between;
}

.registration-search-form {
  display: flex;
  max-width: 300px;
  width: 100%;
  height: 42px;
}

.footer-loader {
  display: flex;
  justify-content: center;
}

.table-data,
.table-header {
  text-align: left;
}

.table-title,
.table-footer {
  padding-left: 1.25rem;
}

.action-dropdown {
  background: #fff;
}

.top-menu-buttons {
  display: flex;
  justify-content: space-between;
}

.activated-status {
  color: #32cd32;
}

.deactivated-status {
  color: #dabd1e;
}

.in-progress-status {
  color: #32cd32;
}

.error-status {
  color: #ff0000;
}
